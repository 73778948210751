import * as angular from 'angular';

angular
    .module('truckingModule')
    .factory('PlannedLoads', PlannedLoadsFactory);

PlannedLoadsFactory.$inject = [ 'Restangular' ];

function PlannedLoadsFactory(Restangular) {
    Restangular.configuration.routeToIdMappings['PlannedLoads'] = 'plannedLoadID';
    return Restangular.service('PlannedLoads');
}