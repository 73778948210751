import 'angular-material';
import 'angular-route';
import 'angular-strap';
import 'restangular';
import 'angular-mm-foundation';
import 'oi.select';
import 'angular-utils-pagination';
import 'angular-xeditable';
import 'spin.js';
import 'angular-spinner';
import 'angular-loading-bar';
import 'angular-ui-grid';
import 'angular-touch';
import 'angular-smart-table';
import 'angular-filter';
import './external/angular-slider/angular-slider';
import 'ui-grid-draggable-rows';
import './external/datetime-picker-slider/range-picker';
import './external/ui-bootstrap/ui-bootstrap-custom-tpls-0.13.1';

import './vendor-styles';