import * as angular from 'angular';

import * as template from './planned-loads.html';
import * as deleteTemplate from '../../Shared/modal-templates/delete-item-modal.html';

angular
    .module('truckingModule')
    .component('htPlannedLoads', {
       controller: plannedLoadsController,
       controllerAs: 'vm',
       template, 
    });

plannedLoadsController.$inject = [
    '$scope',
    '$location',
    'Pagination',
    '$modal',
    'NotificationFactory',
    'PlannedLoadsHelper',
    'PermissionService',
]

function plannedLoadsController(
    $scope,
    $location,
    Pagination,
    $modal,
    NotificationFactory,
    PlannedLoadsHelper,
    PermissionService
) {
    $scope.search = $location.search().search;
    
    $scope.plannedLoads = [];
    $scope.pageOfPlannedLoads = [];
    
    $scope.pagination = {
        itemsPerPage: 10,
        totalItems: $scope.plannedLoads.length,
        currentPage: $location.search().page || 1,
    };
    
    init();
    
    function init() {
        PermissionService.redirectIfUnauthorized('viewPlannedLoads');
    }
    
    $scope.deletePlannedLoad = function(plannedLoad) {
        var modalInstance = $modal.open({
            template: deleteTemplate,
            controller: 'DeleteModalContentController',
            resolve: {
                deletedItemName: function () {
                    return 'this planned load';
                }
            }
        });
        
        modalInstance.result.then(function() {
            PlannedLoadsHelper.remove(plannedLoad)
                .then(function(response) {
                    pageChanged($scope.pagination.currentPage, $scope.pagination.itemsPerPage);
                    NotificationFactory.success("Success: Removed planned load");
                }, function(error) {
                    NotificationFactory.error("Error: Couldn't remove planned load");
                });
        });
    };
    
    var pageChanged = function(page, itemsPerPage) {
        $location.search('page', page);
        PlannedLoadsHelper.getList({
            skip: Pagination.skip(page, itemsPerPage),
            take: itemsPerPage,
            orderBy: 'plannedStartTime'
        })
            .then(function(response) {
                $scope.plannedLoads = response;
                $scope.pagination.totalItems = response.meta.totalResultCount;
            })
    };
    
    $scope.$watch('pagination.currentPage', function(newValue, oldValue) {
        pageChanged(newValue, $scope.pagination.itemsPerPage);
    });
}