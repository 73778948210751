import * as angular from 'angular';

import * as template from './create-or-edit-planned-load.html';

angular
    .module('truckingModule')
    .component('htCreateOrEditPlannedLoad', {
        controller: createOrEditPlannedLoadController,
        controllerAs: 'vm',
        template,
    });

createOrEditPlannedLoadController.$inject = [
    '$scope',
    '$location',
    '$http',
    'Pagination',
    'NotificationFactory',
    'PlannedLoadsHelper',
    '$routeParams',
    'PermissionService',
    'Users',
    'TrucksHelper',
    'TrailersHelper',
    'JobsHelper',
    'CategoriesHelper',
    'MaterialsHelper',
    'BillTypesHelper'
]

function createOrEditPlannedLoadController(
    $scope,
    $location,
    $http,
    Pagination,
    NotificationFactory,
    PlannedLoadsHelper,
    $routeParams,
    PermissionService,
    Users,
    TrucksHelper,
    TrailersHelper,
    JobsHelper,
    CategoriesHelper,
    MaterialsHelper,
    BillTypesHelper
) {
    $scope.plannedLoadId = $routeParams.plannedLoadId;
    
    $scope.employeeList = [];
    $scope.truckList = [];
    $scope.trailerList = [];
    $scope.jobList = [];
    $scope.phaseList = [];
    $scope.categoryList = [];
    $scope.materialList = [];
    $scope.billingTypes = [];
    
    $scope.selectedTrucker = null;
    $scope.selectedTruck = null;
    $scope.selectedTrailer = null;
    $scope.selectedPupTrailer = null;
    $scope.selectedJob = null;
    $scope.selectedPhase = null;
    $scope.selectedCategory = null;
    $scope.selectedMaterial = null;
    $scope.selectedBillingType = null;
    
    init();
    
    function init() {
        PermissionService.redirectIfUnauthorized([ 'editPlannedLoad', 'deletePlannedLoad', 'addPlannedLoad' ]);
    }
    
    if ($scope.plannedLoadId === 'create') {
        Users.getList()
            .then(function(results) {
                $scope.employeeList = results;
            });
        
        TrucksHelper.getList()
            .then(function(results) {
                $scope.truckList = results;
            });

        TrailersHelper.getList()
            .then(function(results) {
                $scope.trailerList = results;
            });

        JobsHelper.getList()
            .then(function(results) {
                $scope.jobList = results;
            });
        
        MaterialsHelper.getList()
            .then(function(results) {
                $scope.materialList = results;
            });

        BillTypesHelper.getList()
            .then(function(results) {
                $scope.billingTypes = results;
            });
    } else {
    
        PlannedLoadsHelper.get($scope.plannedLoadId)
            .then(function(response) {
              
                $scope.plannedLoad = response;
                $scope.plannedLoad.plannedStartTime = new Date($scope.plannedLoad.plannedStartTime);

                Users.getList()
                .then(function(results) {
                    $scope.employeeList = results;
                    var employeeResults = results.filter(function(employee) {
                        if(employee.employeeID == $scope.plannedLoad.truckerId)
                        {
                            return employee;
                        }
                    });
                    
                    $scope.selectedTrucker = employeeResults[0];
                });
            
                TrucksHelper.getList()
                    .then(function(results) {
                        $scope.truckList = results;
                        var truckResults = results.filter(function(truck) {
                            if(truck.equipmentID == $scope.plannedLoad.truckId)
                            {
                                return truck;
                            }
                        });
                        $scope.selectedTruck = truckResults[0];
                    });

                TrailersHelper.getList()
                    .then(function(results) {
                        $scope.trailerList = results;
                        var trailerResults = results.filter(function(trailer) {
                            if(trailer.equipmentID == $scope.plannedLoad.trailerId)
                            {
                                return trailer;
                            }
                        });
                        $scope.selectedTrailer = trailerResults[0];

                        var pupTrailerResults = results.filter(function(pupTrailer) {
                            if(pupTrailer.equipmentID == $scope.plannedLoad.pupTrailerId)
                            {
                                return pupTrailer;
                            }
                        });
                        $scope.selectedPupTrailer = pupTrailerResults[0];
                    });

                JobsHelper.getList()
                    .then(function(results) {
                        $scope.jobList = results;
                        var jobResults = results.filter(function(job) {
                            if(job.jobID == $scope.plannedLoad.jobId)
                            {
                                return job;
                            }
                        });
                        $scope.selectedJob = jobResults[0];
                        //
                        var phasesEndpoint = '/api/Jobs/' + $scope.plannedLoad.jobId + '/Phases';
                        $http.get(phasesEndpoint).then(function(results) {
                            $scope.phaseList = results.data.items;
                            var phaseResults = results.data.items.filter(function(phase) {
                                if(phase.phaseID == $scope.plannedLoad.phaseId)
                                {
                                    return phase;
                                }
                            });
                            $scope.selectedPhase = phaseResults[0];

                            var cagetoryEndpoint = '/api/Jobs/' + $scope.plannedLoad.jobId + '/Phases/' + $scope.plannedLoad.phaseId + '/Categories';
                            $http.get(cagetoryEndpoint).then(function(results) {
                                $scope.categoryList = results.data.items;
                                var cagetoryResults = results.data.items.filter(function(cagetory) {
                                    if(cagetory.cagetoryID == $scope.plannedLoad.cagetoryId)
                                    {
                                        return cagetory;
                                    }
                                });
                                $scope.selectedCategory = cagetoryResults[0];
                            });
                        });
                    });
                
                MaterialsHelper.getList()
                    .then(function(results) {
                        $scope.materialList = results;
                        var materialResults = results.filter(function(material) {
                            if(material.materialID == $scope.plannedLoad.materialId)
                            {
                                return material;
                            }
                        });
                        $scope.selectedMaterial = materialResults[0];
                    });

                BillTypesHelper.getList()
                    .then(function(results) {
                        $scope.billingTypes = results;
                        var billingTypeResults = results.filter(function(billingType) {
                            if(billingType.billTypeID == $scope.plannedLoad.billTypeId)
                            {
                                return billingType;
                            }
                        });
                        $scope.selectedBillingType = billingTypeResults[0];
                    });
            });  
    }

    $scope.getTruckerMatches = function() {
        return $scope.employeeList.filter(function(employee) {
            return employee.name.toLowerCase().contains($scope.truckerSearch.toLowerCase());
        });
    }

    $scope.getTruckMatches = function() {
        return $scope.truckList.filter(function(truck) {
            return truck.name.toLowerCase().contains($scope.truckSearch.toLowerCase());
        });
    }

    $scope.getTrailerMatches = function() {
        return $scope.trailerList.filter(function(trailer) {
            return trailer.name.toLowerCase().contains($scope.trailerSearch.toLowerCase());
        });
    }

    $scope.getPupTrailerMatches = function() {
        return $scope.trailerList.filter(function(trailer) {
            return trailer.name.toLowerCase().contains($scope.pupTrailerSearch.toLowerCase());
        });
    }

    $scope.getJobMatches = function() {
        return $scope.jobList.filter(function(job) {
            return job.name.toLowerCase().contains($scope.jobSearch.toLowerCase());
        });
    }

    $scope.getPhaseMatches = function() {
        return $scope.phaseList.filter(function(phase) {
            return phase.name.toLowerCase().contains($scope.phaseSearch.toLowerCase());
        });
    }

    $scope.getCategoryMatches = function() {
        return $scope.categoryList.filter(function(category) {
            return category.name.toLowerCase().contains($scope.categorySearch.toLowerCase());
        });
    }

    $scope.getMaterialMatches = function() {
        return $scope.materialList.filter(function(material) {
            return material.name.toLowerCase().contains($scope.materialSearch.toLowerCase());
        });
    }

    $scope.getBillingTypeMatches = function() {
        return $scope.billingTypes.filter(function(billingType) {
            return billingType.name.toLowerCase().contains($scope.billingTypeSearch.toLowerCase());
        });
    }

    $scope.isValidSubmit = function() {
        return !$scope.selectedTrucker 
        || !$scope.selectedTruck 
        || !$scope.selectedJob 
        || !$scope.selectedPhase 
        || !$scope.selectedCategory 
        || !$scope.selectedMaterial 
        || !$scope.selectedBillingType;
    }
    
    $scope.jobSelected = function() {
        $scope.selectedPhase = null;
        $scope.selectedCategory = null;
        $scope.categoryList = [];
        var endpoint = '/api/Jobs/' + $scope.selectedJob.jobID + '/Phases';
        $http.get(endpoint).then(function(results) {
            $scope.phaseList = results.data.items;
        });
    }
    
    $scope.phaseSelected = function() {
        $scope.selectedCategory = null;
        var endpoint = '/api/Jobs/' + $scope.selectedJob.jobID + '/Phases/' + $scope.selectedPhase.phaseID + '/Categories';
        $http.get(endpoint).then(function(results) {
            $scope.categoryList = results.data.items;
        });
    }
    
    $scope.savePlannedLoad = function(isValid, plannedLoad) {
        if (!isValid) {
            return;
        }
        if ($scope.selectedTrucker) {
            plannedLoad.truckerId = $scope.selectedTrucker.employeeID;
            plannedLoad.trucker = $scope.selectedTrucker;
            plannedLoad.truckerName = $scope.selectedTrucker.name;
        }
        if ($scope.selectedTruck) {
            plannedLoad.truckId = $scope.selectedTruck.equipmentID;
            plannedLoad.truck = $scope.selectedTruck;
            plannedLoad.truckName = $scope.selectedTruck.name;

        }
        if ($scope.selectedTrailer) {
            plannedLoad.trailerId = $scope.selectedTrailer.equipmentID;
            plannedLoad.trailer = $scope.selectedTrailer;
            plannedLoad.trailerName = $scope.selectedTrailer.name;

        }
        if ($scope.selectedPupTrailer) {
            plannedLoad.pupTrailerId = $scope.selectedPupTrailer.equipmentID;
            plannedLoad.pupTrailer = $scope.selectedPupTrailer;

            plannedLoad.pupTrailerName = $scope.selectedPupTrailer.name;

        }
        if ($scope.selectedJob) {
            plannedLoad.jobId = $scope.selectedJob.jobID;
            plannedLoad.job = $scope.selectedJob;

            plannedLoad.jobName = $scope.selectedJob.name;
            plannedLoad.jobNumber = $scope.selectedJob.jobNumber;
        }
        if ($scope.selectedPhase) {
            plannedLoad.phaseId = $scope.selectedPhase.phaseID;
            plannedLoad.phase = $scope.selectedPhase;

            plannedLoad.phaseName = $scope.selectedPhase.name;
            plannedLoad.phaseNumber = $scope.selectedPhase.phaseNumber;
        }
        if ($scope.selectedCategory) {
            plannedLoad.categoryId = $scope.selectedCategory.categoryID;
            plannedLoad.category = $scope.selectedCategory;
            plannedLoad.categoryName = $scope.selectedCategory.name;
            plannedLoad.categoryNumber = $scope.selectedCategory.categoryNumber;

        }
        if ($scope.selectedMaterial) {
            plannedLoad.materialId = $scope.selectedMaterial.materialID;
            plannedLoad.material = $scope.selectedMaterial;
            plannedLoad.materialName = $scope.selectedMaterial.name;

        }
        if ($scope.selectedBillingType) {
            plannedLoad.billTypeId = $scope.selectedBillingType.billTypeID;
            plannedLoad.billType = $scope.selectedBillingType;
            plannedLoad.billTypeName = $scope.selectedBillingType.name;
        }

        if ($scope.plannedLoadId === 'create') {
            PlannedLoadsHelper.post(plannedLoad)
                .then(function (response) {
                    NotificationFactory.success('Planned Load Created');
                    $location.path('/trucking/planned-loads');
                })
                .catch(function (error) {
                    NotificationFactory.error('There was an error when recording the data, please check that all the data is correct');
                });
        } else {
            PlannedLoadsHelper.patch(plannedLoad)
                .then(function (response) {
                    NotificationFactory.success('Planned Load Changed');
                    $location.path('/trucking/planned-loads');
                })  
                .catch(function (error) {
                    NotificationFactory.error('There was an error when recording the data, please check that all the data is correct');
                });
        }
    }
    
    $scope.cancel = function() {
        NotificationFactory.error('Planned Load Cancelled');
        $location.path('/trucking/planned-loads');
    }
}